import React, {useEffect} from "react";
import styled, {createGlobalStyle} from "styled-components";
import {motion} from "framer-motion";
import axios from "axios";

const App = () => {
    useEffect(() => {
        const sendVisitorInfo = async () => {
            try {
                const response = await axios.get('https://ipapi.co/json/');
                const visitorInfo = {
                    ip: response.data.ip,
                    city: response.data.city,
                    region: response.data.region + ', ' + response.data.country_capital,
                    country: response.data.country_name,
                    userAgent: navigator.userAgent,
                    referrer: document.referrer,
                    portfolio_name: "timilehin.bike",
                };

                console.log('Visitor info:', visitorInfo);
                await axios.post('https://us-central1-tim-portfolios.cloudfunctions.net/newVisitorNotification', visitorInfo);
            } catch (error) {
                console.error('Error sending visitor info:', error);
            }
        };

        sendVisitorInfo().then(r => console.log(r));
    }, []);

    return (
        <>
            <GlobalStyles/>
            <Container>
                <Content>
                    {/* Hero Section */}
                    <motion.div
                        initial={{opacity: 0, y: -20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 1}}
                    >
                        <Hero>
                            <h4>
                                There's nothing of note to observe here — at least,
                                not just yet.
                            </h4>
                            <h2>
                                But one day, this space will roar to life... just like the bike
                                I'll be riding.
                            </h2>
                        </Hero>
                    </motion.div>

                    {/* About Section */}
                    <motion.div
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{delay: 0.5, duration: 1}}
                    >
                        <About>
                            <p>
                                For as long as I can remember, I've dreamt of riding a sport
                                bike—feeling the wind rush past, exploring new paths, and
                                embracing the freedom of the open road. This space is a little
                                piece of that dream, waiting to be realized. Come back when the
                                tires hit the pavement!
                            </p>
                        </About>
                    </motion.div>

                    {/* Footer */}
                    <motion.div
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{delay: 1, duration: 1}}
                    >
                        <Footer>
                            <p>Until then, ride safe, dream big.</p>
                            <small>
                                PS: If you're reading this on a bike, pull over first. Safety
                                first!
                            </small>
                        </Footer>
                    </motion.div>
                </Content>
            </Container>
        </>

    );
};

export default App;

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500&display=swap');

  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Work Sans', sans-serif;
  }

  #root {
    height: 100%;
  }
`;

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
  background: linear-gradient(270deg, #000000, #1a1a1a);
  background-size: 200% 200%;
  animation: gradientAnimation 10s ease infinite;
  color: white;
  font-family: 'Work Sans', sans-serif;
  text-align: center;
  overflow: hidden;

  @keyframes gradientAnimation {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`;

const Content = styled.div`
  max-width: 900px;
  width: 90%;
  display: grid;
  grid-template-rows: auto auto auto;
  gap: 2rem;
`;

const Hero = styled.div`
  h4 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
    background: linear-gradient(90deg, #ff4500, #1e90ff, #32cd32, #ff4500);
    background-size: 300% 300%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textGradient 8s ease infinite;

    @keyframes textGradient {
      0% {
        background-position: 0 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0 50%;
      }
    }

    span {
      font-weight: 900;
    }
  }

  h2 {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
    color: white;
  }
`;

const About = styled.div`
  p {
    font-size: 1rem;
    line-height: 1.9;
    color: white;
    margin: 0;
  }
`;

const Footer = styled.div`
  color: white;

  p {
    font-size: 1rem;
    line-height: 2;
    margin-top: 3em;
    margin-bottom: 0;
  }

  small {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    line-height: 2;
  }
`;
